import "braft-editor/dist/index.css";
import React, { useEffect, useRef, useState } from "react";
import BraftEditor, {
  EditorState,
  // ExtendControlType,
  MediaType,
} from "braft-editor";
import api from "../api/media";
import { useRecoilValue } from "recoil";
import { authState } from "../recoil/authState";
// import { apiUrl } from "../config";

export type UploadFn = MediaType["uploadFn"];
// export type UploadFn = Pick<MediaType, "uploadFn">
type SuccessFn = Parameters<NonNullable<UploadFn>>[0]["success"];

// interface MediaItem {
//   id: string;
//   type: string;
//   url: string;
// }

interface Props {
  value?: EditorState;
  onChange?: (editorState: EditorState) => void;
  uploadFn?: UploadFn;
}

const MyBraftEditor: React.FC<Props> = ({ value, onChange, uploadFn }) => {
  // const [mediaItems, setMediaItems] = useState<Array<MediaItem>>([]);
  const [refreshId, setRefreshId] = useState(0);
  const auth = useRecoilValue(authState);
  const editorRef = useRef<BraftEditor>(null);

  const token = auth.accessToken ?? "";

  useEffect(() => {
    api
      .getAll({ token })
      .then(({ data }: { data: Array<{ fileName: string }> }) => {
        console.log(data);
        //

        const finder = editorRef.current?.getFinderInstance();
        finder.addItems(
          // [
          //   {
          //     id: 1,
          //     type: "IMAGE",
          //     url: "https://margox.cn/wp-content/uploads/2018/09/IMG_9508.jpg",
          //   },
          //   {
          //     id: 2,
          //     type: "IMAGE",
          //     url:
          //       "https://margox.cn/wp-content/uploads/2017/05/IMG_4995-480x267.jpg",
          //   },
          // ]

          data.map((item) => ({
            id: `/media/${item.fileName}`,
            type: "IMAGE",
            // url: `${apiUrl}/${item.fileName}`,
            url: `/media/${item.fileName}`,
          }))
        );
        // setMediaItems(
        //   data.map((item) => ({
        //     id: item.fileName,
        //     type: "IMAGE",
        //     // url: `${apiUrl}/${item.filename}`,
        //     url: item.fileName,
        //   }))
        // );
      });
  }, [token, refreshId]);

  // const successFn: SuccessFn = (params) => {
  //   uploadFn
  //   setRefreshId((id) => id + 1);
  // };

  const newUploadFn: UploadFn = (params) => {
    const success: SuccessFn = (p) => {
      console.log("p", p);
      params.success(p);
      // setRefreshId((id) => id + 1);
    };

    uploadFn && uploadFn({ ...params, success });
  };
  // const mediaItems = [
  //   {
  //     id: 1,
  //     type: "IMAGE",
  //     url: "https://margox.cn/wp-content/uploads/2018/09/IMG_9508.jpg",
  //   },
  //   {
  //     id: 2,
  //     type: "IMAGE",
  //     url: "https://margox.cn/wp-content/uploads/2017/05/IMG_4995-480x267.jpg",
  //   },
  //   {
  //     id: 3,
  //     type: "IMAGE",
  //     url: "https://margox.cn/wp-content/uploads/2017/05/IMG_4984-480x267.jpg",
  //   },
  //   {
  //     id: 4,
  //     type: "AUDIO",
  //     url:
  //       "https://margox.cn/wp-content/uploads/2016/10/Jesper-Kyd-Venice-Rooftops.mp3",
  //   },
  //   {
  //     id: 5,
  //     type: "IMAGE",
  //     url: "https://margox.cn/wp-content/uploads/2016/02/DSC_6961-980x654.jpg",
  //   },
  // ];

  // const extendControls: ExtendControlType[] = [
  //   "separator",
  //   // {
  //   //   key: "add-media",
  //   //   type: "button",
  //   //   text: "插入图片到媒体库",
  //   //   onClick: handleClick,
  //   // },
  //   // {
  //   //   key: "insert-media",
  //   //   type: "button",
  //   //   text: "插入图片到编辑器",
  //   //   onClick: handleClick,
  //   // },
  //   {
  //     key: "my-modal",
  //     type: "modal",
  //     title: "这是一个自定义的下拉组件", // 指定鼠标悬停提示文案
  //     className: "my-modal", // 指定触发按钮的样式名
  //     html: null, // 指定在按钮中渲染的html字符串
  //     text: "弹窗插入图片", // 指定按钮文字，此处可传入jsx，若已指定html，则text不会显示
  //     onClick: () => {}, // 指定触发按钮点击后的回调函数
  //     modal: {
  //       id: "my-modal", // 必选属性，传入一个唯一字符串即可
  //       title: "我的弹窗", // 指定弹窗组件的顶部标题
  //       className: "my-modal", // 指定弹窗组件样式名
  //       width: 500, // 指定弹窗组件的宽度
  //       height: 500, // 指定弹窗组件的高度
  //       showFooter: true, // 指定是否显示弹窗组件底栏
  //       showCancel: true, // 指定是否显示取消按钮
  //       showConfirm: true, // 指定是否显示确认按钮
  //       confirmable: true, // 指定确认按钮是否可用
  //       showClose: true, // 指定是否显示右上角关闭按钮
  //       closeOnBlur: true, // 指定是否在点击蒙层后关闭弹窗(v2.1.24)
  //       closeOnConfirm: true, // 指定是否在点击确认按钮后关闭弹窗(v2.1.26)
  //       closeOnCancel: true, // 指定是否在点击取消按钮后关闭弹窗(v2.1.26)
  //       cancelText: "取消", // 指定取消按钮文字
  //       confirmText: "确定", // 指定确认按钮文字
  //       bottomText: null, // 指定弹窗组件底栏左侧的文字，可传入jsx
  //       onConfirm: () => {}, // 指定点击确认按钮后的回调函数
  //       onCancel: () => {}, // 指定点击取消按钮后的回调函数
  //       onClose: () => {}, // 指定弹窗被关闭后的回调函数
  //       onBlur: () => {}, // 指定蒙层被点击时的回调函数
  //       children: <div>Hello world</div>, // 指定弹窗组件的内容组件
  //     },
  //   },
  // ];

  return (
    <BraftEditor
      ref={editorRef}
      // ref={instance =>}
      value={value}
      onChange={onChange}
      // extendControls={extendControls}
      style={{ border: "1px solid lightblue" }}
      placeholder="请输入正文内容"
      media={{
        // items: mediaItems,
        uploadFn: newUploadFn,
        accepts: {
          image:
            "image/png,image/jpeg,image/gif,image/webp,image/apng,image/svg",
          video: false,
          audio: false,
        },
        externals: {
          image: false,
          video: false,
          audio: false,
          embed: false,
        },
      }}
    />
  );
};

export default MyBraftEditor;

import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { SubmissionErrors } from "final-form";
import {
  Row,
  Col,
  Button,
  Form as RsForm,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import Uploader from "../Uploader";
import { apiUrl } from "../../config";
import sleep from "../../lib/sleep";

interface Props {
  id?: string;
  obj: Record<string, any>;
  onSubmit: (
    values: any
  ) =>
    | SubmissionErrors
    | void
    | undefined
    | Promise<SubmissionErrors | void | undefined>;
}

const Details = ({ id, obj, onSubmit }: Props) => {
  const [image, setImage] = useState(obj.image);
  const [hint, setHint] = useState("");

  useEffect(
    () => {
      setImage(obj.image);
      setHint("");
    },
    // eslint-disable-next-line
    [id]
  );

  const handleFileUpload = (
    xhr: XMLHttpRequest,
    e: ProgressEvent<EventTarget>
  ) => {
    if (xhr.responseText) {
      const resJson = JSON.parse(xhr.responseText);
      setImage(resJson.file);
    } else {
      console.log("xhr response is empty");
    }
  };

  const submit = async (values: any) => {
    setHint("");
    await sleep(500);
    const errors = await onSubmit({ ...values, image });
    if (!errors) setHint("提交成功");
    return errors;
  };

  return (
    <Form onSubmit={submit} initialValues={obj}>
      {({ handleSubmit, form, values, submitting }) => (
        <RsForm onSubmit={handleSubmit} style={{ flex: "50em 1 1" }}>
          <FormGroup row>
            <Label sm={2}>编号</Label>
            <Label sm={10}>{id}</Label>
          </FormGroup>

          <Field name="language">
            {({ input, meta }) => (
              <FormGroup row>
                <Label sm={2}>语言</Label>
                <Col sm={10}>
                  <Input {...input} type={"select"}>
                    <option value="cn">中文</option>
                    <option value="en">英文</option>
                  </Input>
                </Col>
              </FormGroup>
            )}
          </Field>

          <Field name="order">
            {({ input, meta }) => (
              <FormGroup row>
                <Label sm={2}>顺序</Label>
                <Col sm={10}>
                  <Input {...input} type="number" placeholder="顺序" />
                </Col>
              </FormGroup>
            )}
          </Field>

          <Field name="name">
            {({ input, meta }) => (
              <FormGroup row>
                <Label sm={2}>名称</Label>
                <Col sm={10}>
                  <Input {...input} type="text" placeholder="名称" />
                </Col>
              </FormGroup>
            )}
          </Field>

          <Field name="link">
            {({ input, meta }) => (
              <FormGroup row>
                <Label sm={2}>链接</Label>
                <Col sm={10}>
                  <Input {...input} type="text" placeholder="无链接可不填写" />
                </Col>
              </FormGroup>
            )}
          </Field>

          <FormGroup row>
            <Label sm={2}>缩略图</Label>
            <Col sm={10}>
              {image && (
                <>
                  <Row>
                    <img
                      src={`${apiUrl}/uploads/banner/${image}`}
                      style={{
                        maxWidth: "200px",
                        border: "1px solid lightblue",
                      }}
                      alt="thumbnail"
                    />
                  </Row>
                  <Row>
                    <FormText>{image}</FormText>
                  </Row>
                </>
              )}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2}>上传文件</Label>
            <Col sm={10}>
              <Uploader type="image" dir="banner" onLoad={handleFileUpload} />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={2}>状态</Col>
            <Col sm={10}>
              <Field name="isPublished" type="checkbox">
                {({ input }) => (
                  <div>
                    <Label check>
                      <Input {...input} type="checkbox" />
                      发布
                    </Label>
                  </div>
                )}
              </Field>
            </Col>
          </FormGroup>
          <div>
            <Button color="secondary" className="mx-2" onClick={form.reset}>
              重置
            </Button>
            <Button
              color="primary"
              className="mx-2"
              type="submit"
              disabled={submitting}
            >
              {submitting ? "提交中..." : "提交"}
            </Button>
            {hint && (
              <span style={{ fontSize: "0.85em", color: "red" }}>{hint}</span>
            )}
          </div>
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
        </RsForm>
      )}
    </Form>
  );
};
export default Details;

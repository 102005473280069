import React, { CSSProperties } from "react";
import styled from "styled-components";

// const PlusIcon: React.FC<{ className?: string }> = ({ className }) => {
//   return <div className={className}></div>;
// };

// export default styled(PlusIcon)`
//   color: rgb(221, 221, 221);
//   width: 200px;
//   height: 200px;
//   position: relative;

//   &::before,
//   &::after {
//     content: "";
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     background-color: #ddd;
//   }
//   &::before {
//     width: 50%;
//     height: 3px;
//   }

//   &::after {
//     height: 50%;
//     width: 3px;
//   }
// `;

const StyledDiv = styled.div`
  /* color: rgb(221, 221, 221);
  width: 200px;
  height: 200px; */
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ddd;
  }
  &::before {
    width: 50%;
    height: 3px;
  }

  &::after {
    height: 50%;
    width: 3px;
  }
`;

const PlusIcon = ({ style }: { style?: CSSProperties }) => (
  <StyledDiv style={style}></StyledDiv>
);

export default PlusIcon;

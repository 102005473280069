import React from "react";
import { Form, Field, FormProps } from "react-final-form";
import Logo from "../Logo";
import { validateUserName, validatePassword } from "./validation";
import { UserIcon, LockIcon } from "./Icons";

const ShowError:React.FC<{error?: string}> = ({ error }) =>
  error ? (
    // 另一种错误提示风格
    // <div className="text-danger form-text small pl-3">{error}</div>
    <div className="alert-danger form-text small p-2 pl-3">{error}</div>
  ) : null;


const renderInput:React.FC<{input: any, meta: any, label: any, icon: any}> = ({ input, meta, label, icon }) => (
  <div className="form-group ">
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">{icon}</span>
      </div>
      <input className="form-control" {...input} placeholder={label} />
    </div>
    {meta.touched && <ShowError error={meta.error} />}
  </div>
);

interface LoginFormProps extends FormProps {
  isFetching: boolean;
  authError?: {error?:string};
}

const LoginForm:React.FC<LoginFormProps> = ({ onSubmit, isFetching, authError, ...restProps }) => (
  <Form onSubmit={onSubmit} {...restProps}>
    {({ handleSubmit, submitError, pristine }) => {
      return (
        <div
          className="container"
          style={{ marginTop: "7.5rem", width: "30rem" }}
        >
          <h4 className="text-center font-weight-bold text-info">
            <Logo className="logo" style={{ margin: "0.25rem" }} />
          </h4>
          <h4 className="text-center font-weight-bold text-info my-3">
            官网管理后台
          </h4>
          <form className="card" onSubmit={handleSubmit}>
            <div className="card-body pb-0">
              <Field
                name="userName"
                type="text"
                label="用户名"
                icon={<UserIcon />}
                component={renderInput}
                validate={validateUserName}
              />
              <Field
                name="password"
                type="password"
                label="密码"
                icon={<LockIcon />}
                component={renderInput}
                validate={validatePassword}
              />
              <div className="form-group ">
                <button
                  className="btn btn-info btn-block"
                  type="submit"
                  disabled={isFetching || pristine}
                >
                  登录
                </button>
                <ShowError
                  error={submitError || (authError && authError.error)}
                />
              </div>
            </div>
          </form>
        </div>
      );
    }}
  </Form>
);

export default LoginForm;

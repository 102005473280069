import createApiClient from "../lib/createApiClient";
import { apiUrl } from "../config";

const api = createApiClient(apiUrl + "/api/job");

//
const parseJob = (jobJson: any) => ({
  ...jobJson,
  publishedAt: jobJson.publishedAt ? new Date(jobJson.publishedAt) : undefined,
});

//
const getAll = async (options: any) => {
  const { err, data } = await api.getAll(options);

  return {
    err,
    data: data.map((item: any) => parseJob(item)),
  };
};

const jobApi = { ...api, getAll };

export default jobApi;

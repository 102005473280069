import React from "react";
import { Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { login } from "../../api/auth";
import {
  authState,
  useLogin,
  isAuthenticatedState,
} from "../../recoil/authState";
import LoginForm from "./LoginForm";

const Login = () => {
  const auth = useRecoilValue(authState);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const { request, done, fail } = useLogin();
  const location = { state: null };

  const submit = async (values: Record<string, any>) => {
    const { userName, password } = values;

    request();
    const { data, error } = await login(userName, password);

    if (error) {
      fail(error);
    }

    if (data) {
      done(data.userName, data.roles, data.accessToken);
    }
  };

  if (isAuthenticated === true) {
    const { from } = location.state || { from: { pathname: "/" } };
    if (from.pathname === "/login") from.pathname = "/";
    return <Redirect to={from} />;
  }

  return (
    <LoginForm
      onSubmit={submit}
      isFetching={auth.isFetching}
      authError={auth.error}
    />
  );
};

export default Login;

import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import { isAuthenticatedState } from "./recoil/authState";

import PrivateRoute from "./lib/PrivateRoute";

import Layout from "./components/Layout";
import Login from "./components/Login";
import Home from "./components/Banner";
import RecruitmentCase from "./components/RecruitmentCase";
import IndustryInsight from "./components/IndustryInsight";
import TalentRecommendation from "./components/TalentRecommendation";
import Jobs from "./components/Jobs";

// import { routes } from "./routes";

const App = () => {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  return (
    <Router>
      <Switch>
        <Route path="/" exact={true} render={() => <Redirect to="/home" />} />
        <Route path="/login">
          <Login />
        </Route>
        <PrivateRoute path="/home" isAuthenticated={isAuthenticated}>
          <Layout showHeader={true} activeItem="/home">
            <Home />
          </Layout>
        </PrivateRoute>
        <PrivateRoute
          path="/recruitment-case"
          isAuthenticated={isAuthenticated}
        >
          <Layout showHeader={true} activeItem="/recruitment-case">
            <RecruitmentCase />
          </Layout>
        </PrivateRoute>
        <PrivateRoute
          path="/industry-insight"
          isAuthenticated={isAuthenticated}
        >
          <Layout showHeader={true} activeItem="/industry-insight">
            <IndustryInsight />
          </Layout>
        </PrivateRoute>
        <PrivateRoute
          path="/talent-recommendation"
          isAuthenticated={isAuthenticated}
        >
          <Layout showHeader={true} activeItem="/talent-recommendation">
            <TalentRecommendation />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/jobs" isAuthenticated={isAuthenticated}>
          <Layout showHeader={true} activeItem="/jobs">
            <Jobs />
          </Layout>
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default App;

import React from "react";
import styled from "styled-components";

const Footer = (props: React.HtmlHTMLAttributes<HTMLElement>) => (
  <footer {...props}>
    <div className="fixed-bottom bg-primary text-white text-center">
      © 2020 - {new Date().getFullYear()} Archibucks官网管理后台
    </div>
  </footer>
);

export default styled(Footer)`
  height: 3rem;
  margin-top: 1rem;

  & div {
    height: 3rem;
    line-height: 3rem;
  }
`;

import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { apiUrl } from "../config";
import PlusIcon from "./PlusIcon";
import upload from "../lib/upload";

interface Props {
  type: "image" | "video";
  dir: "banner" | "thumbnail" | "media";
  onLoad: (xhr: XMLHttpRequest, e: ProgressEvent<EventTarget>) => any;
}

const Uploader: React.FC<Props> = ({ type, dir, onLoad }) => {
  const [value, setValue] = useState<File>();

  // const upload = () => {
  //   if (!value) return;

  //   const fd = new FormData();
  //   fd.append("upImage", value);

  //   const xhr = new XMLHttpRequest();

  //   // xhr.upload.onprogress = onProgress;
  //   // xhr.upload.onload = onLoad;
  //   // xhr.upload.onloadend = onLoad;
  //   xhr.addEventListener("load", function (e) {
  //     onLoad(this, e);
  //   });

  //   // XHR - Make Request
  //   xhr.open("POST", apiUrl + "/api/upload/" + dest, true);
  //   xhr.send(fd);
  // };

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    upload({ file, type, dir, onLoad });
    setValue(file);
  };

  const acceptByType: Record<string, string> = {
    image: "image/jpeg, image/png",
    video: "video/*",
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptByType[type],
    multiple: false,
  });

  return (
    <section>
      <p>将图片文件拖动到此处，或点击选择文件</p>
      <div
        style={{
          width: "450px",
          height: "230px",
          margin: ".5em",
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <div>
          <div
            {...getRootProps({
              style: {
                width: "200px",
                height: "200px",
                border: "2px dashed #ddd ",
              },
            })}
          >
            <PlusIcon
              style={{
                color: "rgb(221, 221, 221)",
                width: "200px",
                height: "200px",
              }}
            />
            <input {...getInputProps()} />
          </div>
          <p style={{ lineHeight: "1.5em", padding: "0.5em" }}>
            图片：
            {/* {JSON.stringify(value, null, 2)}
            {typeof value} */}
            {/* {value?.path} */}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Uploader;

import React from "react";

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  small?: boolean;
}

const Logo = ({ small, ...restProps }: Props) => {
  return (
    <img
      src={small ? "/logo-small.png" : "/logo.png"}
      alt="logo"
      {...restProps}
    />
  );
};

export default Logo;

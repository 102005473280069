import React from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import {
  authState,
  useLogout,
  isAuthenticatedState,
} from "../../recoil/authState";
import Logo from "../Logo";

const links = [
  { name: "首页配置", to: "/home" },
  { name: "招聘服务案例", to: "/recruitment-case" },
  { name: "行业洞见", to: "/industry-insight" },
  { name: "人才推荐", to: "/talent-recommendation" },
  { name: "招聘职位", to: "/jobs" },
];

const StyledLogo = styled(Logo)`
  width: auto;
  height: 2em;
`;

const Header = ({ activeItem = "/" }: { activeItem?: string }) => {
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const { userName } = useRecoilValue(authState);
  const logout = useLogout();

  return isAuthenticated ? (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
      <a className="navbar-brand" href="##">
        <StyledLogo small />
        <span className="text-white" />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mr-auto">
          {links.map((link, index) => (
            <li
              key={link.name}
              className={
                link.to === activeItem ? "nav-item active p-2" : "nav-item p-2"
              }
            >
              <Link to={link.to} className="nav-link p-2">
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
        <div>
          <span className="text-white p-2">你好，{userName}！ </span>
          <button className="btn btn-light px-3" onClick={logout}>
            退出
          </button>
        </div>
      </div>
    </nav>
  ) : null;
};

export default Header;

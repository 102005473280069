import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { SubmissionErrors } from "final-form";
import { useRecoilValue } from "recoil";
import {
  Row,
  Col,
  Button,
  Form as RsForm,
  FormGroup,
  Label,
  Input,
  FormText,
} from "reactstrap";
import BraftEditor from "braft-editor";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Uploader from "../Uploader";
import MyBraftEditor, { UploadFn } from "../MyBraftEditor";
import { apiUrl } from "../../config";
import upload from "../../lib/upload";
import { authState } from "../../recoil/authState";
import api from "../../api/media";
import sleep from "../../lib/sleep";

interface Props {
  id?: string;
  obj: Record<string, any>;
  onSubmit: (
    values: any
  ) =>
    | SubmissionErrors
    | void
    | undefined
    | Promise<SubmissionErrors | void | undefined>;
}

const Details = ({ id, obj, onSubmit }: Props) => {
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState("")
  );

  const [image, setImage] = useState(obj.image);
  const [hint, setHint] = useState("");

  const auth = useRecoilValue(authState);
  const token = auth.accessToken ?? "";

  useEffect(
    () => {
      setEditorState(BraftEditor.createEditorState(obj.contentHtml));
      setImage(obj.image);
      setHint("");
    },
    // eslint-disable-next-line
    [id]
  );

  const handleThumbnailUpload = (
    xhr: XMLHttpRequest,
    e: ProgressEvent<EventTarget>
  ) => {
    if (xhr.responseText) {
      const resJson = JSON.parse(xhr.responseText);
      setImage(resJson.file);
    } else {
      console.log("xhr response is empty");
    }
  };

  const handleMediaUpload: UploadFn = ({ file, progress, success }) => {
    upload({
      file,
      type: "image",
      dir: "media",
      onProgress: (xhr, e) => {
        progress((e.loaded / e.total) * 100);
      },
      onLoad: async (xhr, e) => {
        console.log(xhr.responseText);
        const file = JSON.parse(xhr.responseText)["file"];

        const res = await api.create({
          entity: {
            // id:
            type: "IMAGE",
            fileName: file,
            dir: "media",
          },
          token,
        });

        console.log("api create response", res);

        success({
          url: `/media/${file}`,
          meta: {
            id: `/media/${file}`,
            title: file,
            alt: "media",
            loop: false, // 指定音视频是否循环播放
            autoPlay: true, // 指定音视频是否自动播放
            controls: true, // 指定音视频是否显示控制栏
            poster: "", // 指定视频播放器的封面
          },
        });
      },
    });
  };

  const submit = async (values: any) => {
    const newValues = {
      ...values,
      image,
      category: "talent-recommendation",
      contentHtml: editorState?.toHTML(),
    };

    setHint("");
    await sleep(500);
    const errors = await onSubmit(newValues);
    if (!errors) setHint("提交成功");
    return errors;
  };

  return (
    <Form onSubmit={submit} initialValues={obj}>
      {({ handleSubmit, form, values, submitting }) => (
        <RsForm onSubmit={handleSubmit} style={{ flex: "50em 1 1" }}>
          <FormGroup row>
            <Label sm={2}>编号</Label>
            <Label sm={10}>{id}</Label>
          </FormGroup>
          <Field name="language">
            {({ input, meta }) => (
              <FormGroup row>
                <Label sm={2}>语言</Label>
                <Col sm={10}>
                  <Input {...input} type={"select"}>
                    <option value="cn">中文</option>
                    <option value="en">英文</option>
                  </Input>
                </Col>
              </FormGroup>
            )}
          </Field>

          <Field name="title">
            {({ input, meta }) => (
              <FormGroup row>
                <Label sm={2}>标题</Label>
                <Col sm={10}>
                  <Input {...input} type="text" placeholder="标题" />
                </Col>
              </FormGroup>
            )}
          </Field>

          {/* <Field name="summary">
            {({ input, meta }) => (
              <FormGroup row>
                <Label sm={2}>简介</Label>
                <Col sm={10}>
                  <Input {...input} type="textarea" placeholder="简介" />
                </Col>
              </FormGroup>
            )}
          </Field> */}

          <FormGroup row>
            <Label sm={2}>缩略图</Label>
            <Col sm={10}>
              {image && (
                <>
                  <Row>
                    <img
                      src={`${apiUrl}/uploads/thumbnail/${image}`}
                      style={{
                        maxWidth: "200px",
                        border: "1px solid lightblue",
                      }}
                      alt="thumbnail"
                    />
                  </Row>
                  <Row>
                    <FormText>{image}</FormText>
                  </Row>
                </>
              )}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2}>上传文件</Label>
            <Col sm={10}>
              <Uploader
                type="image"
                dir="thumbnail"
                onLoad={handleThumbnailUpload}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2}>内容</Label>
            <Col sm={10}>
              <MyBraftEditor
                value={editorState}
                onChange={(state) => setEditorState(state)}
                uploadFn={handleMediaUpload}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={2}>状态</Col>
            <Col sm={10}>
              <Field name="isPublished" type="checkbox">
                {({ input }) => (
                  <FormGroup check>
                    <Label check>
                      <Input {...input} type="checkbox" />
                      发布
                    </Label>
                  </FormGroup>
                )}
              </Field>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={2}>发布日期</Col>
            <Col sm={10}>
              <Field name="publishedAt">
                {({ input }) => (
                  <DatePicker
                    selected={input.value}
                    onChange={(date) =>
                      input.onChange({ target: { value: date } })
                    }
                    showTimeSelect
                    dateFormat="yyyy-MM-dd HH:mm"
                    className="form-control"
                  />
                )}
              </Field>
            </Col>
          </FormGroup>

          <div>
            <Button color="secondary" className="mx-2" onClick={form.reset}>
              重置
            </Button>
            <Button
              color="primary"
              className="mx-2"
              type="submit"
              disabled={submitting}
            >
              {submitting ? "提交中..." : "提交"}
            </Button>
            {hint && (
              <span style={{ fontSize: "0.85em", color: "red" }}>{hint}</span>
            )}
          </div>
        </RsForm>
      )}
    </Form>
  );
};

export default Details;

import { formData } from "./formData";
import { apiUrl } from "../config";

interface Params {
  file?: File;
  type: "image" | "video";
  dir: "banner" | "thumbnail" | "media" | string;
  onLoad: (xhr: XMLHttpRequest, e: ProgressEvent<EventTarget>) => any;
  onProgress?: (xhr: XMLHttpRequest, e: ProgressEvent<EventTarget>) => any;
}

const upload = ({ file, type, dir, onLoad, onProgress }: Params) => {
  if (!file) return;

  const fd = formData({ file, type, dir });

  const xhr = new XMLHttpRequest();

  // xhr.upload.onprogress = onProgress;
  // xhr.upload.onload = onLoad;
  xhr.addEventListener("load", function (e) {
    onLoad(this, e);
  });

  if (onProgress) {
    xhr.addEventListener("progress", function (e) {
      onProgress(this, e);
    });
  }

  // XHR - Make Request
  xhr.open("POST", apiUrl + "/api/upload", true);
  xhr.send(fd);
};

export default upload;

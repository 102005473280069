import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

interface Props extends RouteProps {
  loginUrl?: string;
  isAuthenticated: boolean;
}

const PrivateRoute = ({
  loginUrl = "/login",
  isAuthenticated,
  ...routeProps
}: Props) => {
  return isAuthenticated ? (
    <Route {...routeProps} />
  ) : (
    <Redirect
      to={{ pathname: loginUrl, state: { from: routeProps.location } }}
    />
  );
};

export default PrivateRoute;

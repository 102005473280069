import { useCallback } from "react";
import { atom, selector, useSetRecoilState } from "recoil";

interface AuthState {
  isFetching: boolean;
  userId?: string;
  userName?: string;
  roles: Array<string>;
  accessToken?: string;
  error?: any;
}

const initialState: AuthState = {
  isFetching: false,
  userId: undefined,
  userName: undefined,
  roles: [],
  accessToken: undefined,
  error: null,
};

export const authState = atom({
  key: "authState",
  default: { ...initialState },
});

export const isAuthenticatedState = selector({
  key: "isAuthenticatedState",
  get: ({ get }) => {
    return !!get(authState).userId;
  },
});

export const useLogin = () => {
  const setAuthState = useSetRecoilState<AuthState>(authState);

  const request = useCallback(() => {
    setAuthState((oldState) => ({ ...oldState, isFetching: true }));
  }, [setAuthState]);

  const done = useCallback(
    (userName: string, roles: Array<string>, accessToken: string) => {
      setAuthState({
        isFetching: false,
        userId: userName,
        userName,
        roles,
        accessToken,
      });
    },
    [setAuthState]
  );

  const fail = useCallback(
    (error: any) => {
      setAuthState((oldState) => ({
        ...oldState,
        isFetching: false,
        error,
      }));
    },
    [setAuthState]
  );

  const logout = useCallback(() => {
    setAuthState({ ...initialState });
  }, [setAuthState]);

  return {
    request,
    done,
    fail,
    logout,
  };
};

export const useLogout = () => {
  const setAuthState = useSetRecoilState<AuthState>(authState);

  const logout = () => {
    setAuthState({ ...initialState });
  };

  return useCallback(logout, [setAuthState]);
};

// // reducers
// const reducer = (state = initialState, action) => {
//   switch (action.type) {
//     case types.LOGIN_REQUEST:
//       return { ...initialState, isFetching: true };
//     case types.LOGIN_SUCCESS:
//       const { userName, roles, accessToken } = action.payload;
//       return {
//         isFetching: false,
//         userId: userName,
//         userName,
//         roles,
//         accessToken,
//       };
//     case types.LOGIN_FAILURE:
//       return { ...initialState, error: action.payload };
//     case types.LOGOUT:
//       return { ...initialState };
//     default:
//       return state;
//   }
// };

export const apiUrl = process.env.REACT_APP_API_URL;

export const cityList = [
  { name: "北京", en: "Beijing" },
  { name: "上海", en: "Shanghai" },
  { name: "深圳", en: "Shenzhen" },
  { name: "广州", en: "Guangzhou" },
  { name: "杭州", en: "Hangzhou" },
  { name: "成都", en: "Chengdu" },
  { name: "重庆", en: "Chongqing" },
  { name: "武汉", en: "Wuhan" },
  { name: "西安", en: "Xi'an" },
  { name: "天津", en: "Tianjin" },
  { name: "苏州", en: "Suzhou" },
  { name: "南京", en: "Nanjing" },
  { name: "郑州", en: "Zhengzhou" },
  { name: "长沙", en: "Changsha" },
  { name: "沈阳", en: "Shenyang" },
  { name: "青岛", en: "Qingdao" },
  { name: "合肥", en: "Hefei" },
  { name: "宁波", en: "Ningbo" },
  { name: "厦门", en: "Xiamen" },
  { name: "济南", en: "Jinan" },
  { name: "常州", en: "Changzhou" },
  { name: "香港", en: "Hong Kong" },
  { name: "海外", en: "Overseas" },
  { name: "精选职位", en: "Monthly Positons" },
];

export const industryList = [
  { name: "建筑设计", en: "Architecture Design" },
  { name: "室内设计", en: "Interior Design" },
  { name: "景观设计", en: "Landscape Design" },
  { name: "规划设计", en: "Urban Design" },
  { name: "结构设计", en: "Structure Design" },
  { name: "项目管理", en: "Project Management" },
  { name: "市场商务", en: "Business Development" },
  { name: "地产开发商", en: "Developer" },
  { name: "施工工程", en: "Construction Engineering" },
  { name: "人力资源及行政", en: "HR & Admin" },
  { name: "平面设计", en: "Graphic Design" },
  { name: "房地产咨询", en: "Real Estate Consulting" },
  { name: "IT类", en: "IT" },
  { name: "其他", en: "Others" },
];

export const jobTypeList = [{ name: "Contract" }, { name: "Permanent" }];

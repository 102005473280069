import { apiUrl } from "../config";

export const login = async (userName: string, password: string) => {
  try {
    // run login request
    const params = new URLSearchParams({
      grant_type: "password",
      userName,
      password,
    });

    console.log("apiUrl: " + apiUrl);

    const res = await fetch(new URL("/token", apiUrl).href, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
    });

    if (!res.ok) {
      // statuscode = 400代表用户名、密码错误
      let error;
      if (res.status === 401) error = { error: "用户名或密码不正确" };
      else if (res.status === 500)
        error = { error: "无法连接服务器，请稍后重试" };
      else error = { error: `${res.status}: ${res.statusText}` };

      // yield put(actions.loginFailure(error));
      // return;
      return { error };
    }

    //   const respJson = yield apply(resp, resp.json, []);
    const resJson = await res.json();
    //   yield put(
    //     actions.loginSuccess({
    //       ...respJson,
    //     })
    //   );
    console.log("respJson", resJson);
    return { data: resJson };
  } catch (err) {
    //   yield put(actions.loginFailure({ error: "无法连接服务器，请稍后重试" }));
    //   console.log("err", err);

    console.log("err", err);
    return { error: "无法连接服务器，请稍后重试" };
  }
};

import createApiClient from "../lib/createApiClient";
import { apiUrl } from "../config";

const api = createApiClient(apiUrl + "/api/article");

//
const parseArticle = (articleJson: any) => ({
  ...articleJson,
  publishedAt: articleJson.publishedAt
    ? new Date(articleJson.publishedAt)
    : undefined,
});

//
const getAll = async (options: any) => {
  const { err, data } = await api.getAll(options);

  return {
    err,
    data: data.map((item: any) => parseArticle(item)),
  };
};

const articleApi = { ...api, getAll };

export default articleApi;

import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { useRecoilValue } from "recoil";
import api from "../../api/job";
import { authState } from "../../recoil/authState";
import Master from "./Master";
import Details from "./Details";
import { cityList, industryList } from "../../config";

const Jobs = () => {
  const auth = useRecoilValue(authState);
  const [list, setList] = useState([]);
  const [currentId, setCurrentId] = useState<string>();

  const token = auth.accessToken ?? "";

  const getAll = useCallback(async () => {
    const { data } = await api.getAll({
      token,
      query: { includeUnpublished: "true" },
    });
    setList(data);
  }, [token]);

  useEffect(() => {
    getAll();
  }, [getAll]);

  const del = async (id: string) => {
    await api.del({ id, token });
    if (currentId === id) setCurrentId("");
    await getAll();
  };

  const update = async (id: string, entity: Record<string, any>) => {
    await api.update({ id, entity, token });
    await getAll();
  };

  const create = async (entity: Record<string, any>) => {
    const {
      data: { id },
    } = await api.create({ entity, token });
    await getAll();
    setCurrentId(id);
  };

  const getObj = () => {
    if (currentId) {
      const obj = list.find(({ id }) => id === currentId);
      if (obj) {
        return obj;
      }
    }

    // default values
    return {
      language: "cn",
      location: cityList[0].en,
      industry: industryList[0].en,
      // type: "Contract",
      isPublished: false,
    };
  };

  const onSubmit = async (values: any) => {
    console.log({ values });

    if (currentId) {
      await update(currentId, {
        ...values,
        id: currentId,
      });
    } else {
      await create(values);
    }
  };

  return (
    <Container fluid style={{ width: "90%" }}>
      <Row>
        <Col sm={5}>
          <Master
            list={list}
            onSelect={(id) => setCurrentId(id)}
            onCreate={() => setCurrentId(undefined)}
            onRemove={(id) => {
              if (window.confirm("确认删除？")) del(id);
            }}
            currentId={currentId}
            className=""
          />
        </Col>
        <Col>
          <Details id={currentId} obj={getObj()} onSubmit={onSubmit} />
        </Col>
      </Row>
    </Container>
  );
};

export default Jobs;

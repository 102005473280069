import React from "react";
import styled from "styled-components";
import { Button, Label } from "reactstrap";

interface Props {
  list: Array<any>;
  onSelect: (id: string) => void;
  onCreate?: () => void;
  onRemove: (id: string) => void;
  currentId?: string;
  className?: string;
}

interface SectionProps {
  caption?: string;
  language: "cn" | "en";
}

const Master = ({
  list,
  onSelect,
  onCreate,
  onRemove,
  currentId,
  className,
}: Props) => {
  const Section = ({ caption, language }: SectionProps) => (
    <>
      <p>{caption}</p>
      <ul>
        {list
          .filter((item) => item.language === language)
          .map(({ id, title, isPublished }) => (
            <li
              key={id}
              onClick={() => onSelect(id)}
              className={id === currentId ? "active" : undefined}
            >
              {isPublished
                ? title || "无标题"
                : `${title || "无标题"} - 未发布`}
              <span
                onClick={(e) => {
                  e.preventDefault();
                  onRemove(id);
                }}
                className="remove"
                role="img"
                aria-label="remove"
              >
                ❌
              </span>
            </li>
          ))}
      </ul>
    </>
  );
  return (
    <div className={className}>
      <div className="header">
        <Label className="title">人才推荐</Label>
        <Button color="primary" className="mb-2 ml-2" onClick={onCreate}>
          新增
        </Button>
      </div>
      {list?.length ? (
        <>
          <Section language="cn" caption="中文" />
          <Section language="en" caption="英文" />
        </>
      ) : (
        <div>暂无数据</div>
      )}
    </div>
  );
};

export default styled(Master)`
  .header .title {
    font-size: 24px;
  }

  li.active,
  li:hover {
    font-size: 1.1em;
    color: blue;
    font-weight: bolder;
  }

  .remove {
    cursor: pointer;
    font-size: 0.85em;
    padding: 0 1em;
    visibility: hidden;
  }

  li:hover .remove {
    visibility: visible;
  }
`;

import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({
  activeItem,
  children,
  showHeader = true,
  showFooter = true,
}: {
  activeItem?: string;
  children?: any;
  showHeader?: boolean;
  showFooter?: boolean;
}) => (
  <div>
    {showHeader && <Header activeItem={activeItem} />}
    <div style={{ padding: "2em" }}>{children}</div>
    {showFooter && <Footer />}
  </div>
);

export default Layout;

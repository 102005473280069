import React from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";

type Props = Omit<FontAwesomeIconProps, "icon">;

export const UserIcon = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faUser} />
);

export const LockIcon = (props: Props) => (
  <FontAwesomeIcon {...props} icon={faLock} />
);

import { formData } from "./formData";

const createApiClient = (url: string) => {
  interface GetOption {
    token?: string;
    query?: string | Record<string, string>;
  }
  const getAll = async ({ token, query }: GetOption) => {
    const headers: Record<string, string> = {};
    if (token) headers["Authorization"] = `Bearer ${token}`;

    let search = "";
    if (query) {
      if (typeof query === "string") search = query;

      if (typeof query === "object") {
        search = new URLSearchParams(query).toString();
      }
    }

    const res = await fetch(search ? url + "?" + search : url, {
      method: "GET",
      headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
    });

    const resJson = await res.json();

    return {
      err: "",
      data: resJson,
    };
  };

  interface DelOption {
    id: string;
    token: string;
  }
  const del = async ({ id, token }: DelOption) => {
    const headers: Record<string, string> = {};
    if (token) headers["Authorization"] = `Bearer ${token}`;

    const res = await fetch(`${url}/${id}`, {
      method: "DELETE",
      headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      // body: JSON.stringify({ id }),
    });

    const resJson = await res.json();

    return {
      err: "",
      data: resJson,
    };
  };

  /**
   * contentType 一般情况下使用json，如果含文件的上传，使用form
   */
  interface UpdateOption {
    id: string;
    entity: Record<string, any>;
    token: string;
    contentType?: "form" | "json";
  }

  const update = async ({
    id,
    entity,
    token,
    contentType = "json",
  }: UpdateOption) => {
    const headers: Record<string, string> = {};
    if (token) headers["Authorization"] = `Bearer ${token}`;
    if (contentType === "json") headers["Content-Type"] = "application/json";

    // headers["Content-Type"] =
    //   contentType === "form" ? "multipart/form-data" : "application/json";

    const res = await fetch(`${url}/${id}`, {
      method: "PUT",
      headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: contentType === "form" ? formData(entity) : JSON.stringify(entity),
    });

    const resJson = await res.json();

    return {
      err: "",
      data: resJson,
    };
  };

  /**
   * contentType 一般情况下使用json，如果含文件的上传，使用form
   */

  interface CreateOption {
    entity: Record<string, any>;
    token: string;
    contentType?: "form" | "json";
  }

  const create = async ({
    entity,
    token,
    contentType = "json",
  }: CreateOption) => {
    const headers: Record<string, string> = {};
    if (token) headers["Authorization"] = `Bearer ${token}`;
    if (contentType === "json") headers["Content-Type"] = "application/json";

    // headers["Content-Type"] =
    //   contentType === "form" ? "multipart/form-data" : "application/json";

    const res = await fetch(url, {
      method: "POST",
      headers,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      body: contentType === "form" ? formData(entity) : JSON.stringify(entity),
    });

    const resJson = await res.json();

    return {
      err: "",
      data: resJson,
    };
  };

  return { getAll, create, del, update };
};

export default createApiClient;
